.toast {
  --end-pos: 24px;
  --start-pos: 0px;
  --end-opacity: 1;
  --start-opacity: 0.1;
  --visible: 1;

  position: absolute;
  z-index: 1000;
  left: 0;

  top: 0px;
  transform: opacity 3s ease-in-out;
  display: inline-flex;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
  background: transparent;
  justify-content: center;
  color: #fff;

  width: 100%;
}

.toast--visible {
  opacity: var(--visible);
}

.comeAndBack,
.dayComeAndBack {
  position: relative;
  top: var(--end-pos);
  display: inline-flex;
  gap: 8px;
  padding: 10px 16px;
  align-items: center;
  animation-name: mymove, fade;
  background: #2d2d2d;
  animation-duration: 0.5s;
  -webkit-animation-name: mymove, fade;
  -webkit-animation-duration: 0.5s;
  /* Safari and Chrome */
  opacity: var(--visible);
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

}

.dayComeAndBack {
  background: #fff;
  color: #2d2d2d;
}

.toast__icon--success,
.toast__icon--warn,
.toast__icon--error,
.toast__icon--info,
.toast__icon--loading {
  min-width: 16px;
  display: flex;
  align-items: center;
}

@-webkit-keyframes fade {
  from {
    opacity: var(--start-opacity);
  }

  to {
    opacity: var(--end-opacity);
  }
}

@keyframes fade {
  from {
    opacity: var(--start-opacity);
  }

  to {
    opacity: var(--end-opacity);
  }
}

@keyframes mymove {
  from {
    top: var(--start-pos);
  }

  to {
    top: var(--end-pos);
  }
}

@-webkit-keyframes mymove

/* Safari and Chrome */
  {
  from {
    top: var(--start-pos);
  }

  to {
    top: var(--end-pos);
  }
}