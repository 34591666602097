div.dui-fullscreen-container {
  position: absolute !important;
  outline: none;
  animation-name: show;
  animation-duration: .1s;
  animation-timing-function: ease-out;
  font-family: inherit;
}

.dui-fullscreen-container:focus-visible {
  outline: none;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dui-fullscreen-container div.ant-drawer-header {
  padding: 24px 32px 16px 32px;
  background-color: #FBFBFB;
}

.dui-fullscreen-container div.ant-drawer-header button.ant-drawer-close {
  color: #7a7b7b
}

.dui-fullscreen-container div.ant-drawer-header button.ant-drawer-close:hover,
.dui-fullscreen-container div.ant-drawer-header button.ant-drawer-close:active,
.dui-fullscreen-container div.ant-drawer-header button.ant-drawer-close:focus,
.dui-fullscreen-container div.ant-drawer-header button.ant-drawer-close:focus-visible,
.dui-fullscreen-container div.ant-drawer-header button.ant-drawer-close:focus-within {
  background-color: #f1f1f1;
}

.dui-fullscreen-container div.ant-drawer-body {
  padding: 0;
  background-color: #FBFBFB;
}

.dui-fullscreen-container div.ant-drawer-content-wrapper {
  border-radius: 8px;
  box-shadow: none;
}

.dui-fullscreen-content {
  border-radius: 8px;
}