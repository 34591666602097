/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.dui-button-container {
  display: flex;
  padding: 0px 7px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 6px;
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-1, #FFF);

  cursor: pointer;
  color: var(--Text-Secondary, #7A7B7B);
}

.dui-button-container:hover {
  border: 1px solid var(--Brand-B5, #575757);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.dui-button-container:active {
  color: #1A1A1A;
  border: 1px solid var(--Brand-B7, #2D2D2D);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.dui-button-container:active div svg path {
  fill: #1A1A1A;
}


.dui-button-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
}