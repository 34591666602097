.dui-select-container .rc-virtual-list .rc-virtual-list-holder-inner div[aria-selected=true]::after {
  content: '';
  position: absolute;
  top: 14px;
  right: 6px;
  width: 12px;
  height: 7px;
  border: 1.5px solid #2D2D2D;
  border-top: none;
  border-right: none;
  z-index: 1;
  transform: translate(-50%, -50%) rotate(-45deg);
}