.dui-drawer-container div.ant-drawer-header {
  padding: 24px 32px 16px 32px;
  background-color: #F5F5F5;
}

.dui-drawer-container .ant-drawer-mask {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.dui-drawer-container button.ant-drawer-close {
  display: none;
}

.dui-drawer-container div.ant-drawer-body {
  padding: 16px 32px;
  background-color: #E6E6E6;
}