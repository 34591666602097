button.dui-switch-container.ant-switch-checked {
  background-color: #2d2d2d;
}

button.dui-switch-container.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #2d2d2d;
  opacity: 0.8;
}


button.dui-switch-container {
  background-color: rgba(0, 0, 0, 0.25);
}

button.dui-switch-container.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}