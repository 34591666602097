.DLButton {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.DLButton:hover {
  cursor: pointer;
}

.DLButton-Default,
.DLButton-Medium {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 4px 14px;
  font-size: 14px;
}

.DLButton-Small {
  padding: 2px 8px;
  font-size: 12px;
}

.DLButton-Large {
  padding: 6px 18px;
  font-size: 16px;
}

.Primary-Button {
  border: 1px solid var(--Brand-B6, #464646);
  background: var(--Brand-B6, #464646);
  color: var(--text-primary-inverse, #FFF);
}

.Primary-Button:hover {
  border: 1px solid var(--Brand-B5, #575757);
  background: var(--Brand-B5, #575757);
}

.Primary-Button:active {
  border: 1px solid var(--Brand-B7, #2D2D2D);
  background: var(--Brand-B7, #2D2D2D);
}

.Primary-Button:disabled {
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  background: var(--Neutral-new-3, #F5F5F5);

  color: var(--text-disable-placeholder, #B7B8B9);
}

.Secondary-Button {
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  background: var(--Neutral-new-1, #FFF);
  color: var(--Text-Primary, #2D2D2D);
}

.Secondary-Button:hover {
  border: 1px solid var(--Brand-B5, #575757);
}

.Secondary-Button:active {
  border: 1px solid var(--Brand-B7, #2D2D2D);
}

.Secondary-Button:disabled {
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  color: var(--text-disable-placeholder, #B7B8B9);
}

.Ghost-Button {
  border: 1px solid var(--Neutral-new-1, #FFF);
  color: var(--Neutral-new-1, #FFF);
}

.Ghost-Button:hover {
  background: rgba(255, 255, 255, 0.20);
}

.Ghost-Button:disabled {
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  color: var(--Neutral-new-5, #D9D9D9);
}

.Text-Button {
  padding: 0px;
  color: var(--Text-Secondary, #7A7B7B);
}

.Text-Button:hover {
  color: var(--Text-Primary, #2D2D2D);
}

.Text-Button:disabled {
  color: var(--text-disable-placeholder, #B7B8B9);
}

.Link-Button {
  color: var(--Text-Secondary, #7A7B7B);
}

.Link-Button:hover {
  color: var(--Text-Primary, #2D2D2D);
}

.Link-Button:disabled {
  color: var(--text-disable-placeholder, #B7B8B9);
}

.IconPrimary-Button {
  padding: 7px;
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  background: var(--Neutral-new-1, #FFF);
  color: #9E9E9E;
}

.IconPrimary-Button:hover {
  border: 1px solid var(--Brand-B6, #464646);
  color: #2D2D2D;
}

.IconPrimary-Button:active {
  border: 1px solid var(--Brand-B7, #2D2D2D);
  color: #2D2D2D;
}

.IconPrimary-Button:disabled {
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  color: #B7B8B9;
}

.IconSecondary-Button {
  padding: 4px;
  color: #9E9E9E;
}

.IconSecondary-Button:hover {
  color: #2D2D2D;
}

.IconSecondary-Button:disabled {
  color: #B7B8B9;
}

.IconCircle-Button {
  padding: 7px;
  border-radius: 100px;
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  background: var(--Neutral-new-1, #FFF);
  color: #9E9E9E;
}

.IconCircle-Button:hover {
  border: 1px solid var(--Brand-B6, #464646);
  color: #2D2D2D;
}

.IconCircle-Button:disabled {
  border: 1px solid var(--Neutral-new-5, #D9D9D9);
  color: #B7B8B9;
}

.Content-Default,
.Content-Medium {
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.7px;
}
.Content-Small{
  font-size: 12px;
  line-height: 18px;
}

.Content-Large {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.8px;
}

.IconPrimary-Content,
.IconSecondary-Content,
.IconCircle-Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(---HitBox, rgba(255, 255, 255, 0.00));
}
