.dui-image-viewer-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dui-image-viewer-item-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  gap: 10px;

  color: var(--character-primary-inverse, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

}

.dui-image-viewer-title {
  max-width: 1042px;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dui-image-viewer-image-container {
  width: 1042px;
  max-width: 1042px;
  max-height: 760px;
  display: flex;
  justify-content: center;
  align-items: center;
}