.example * {
  padding: 0;
  margin: 0;
}

.example {
  font-size: 14px;
  cursor: pointer;
  color: #333;
}

.example h3 {
  font-size: 24px;
  margin-bottom: 12px;
}

.example-group {
  overflow: hidden;
  margin-bottom: 32px;
}

.example-group__item {
  float: left;
  margin: 3px 0;
  width: 16.66%;
  height: 100px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.example-group__item:hover {
  color: #fff;
  background-color: #1677ff;
}
.example-group__item:hover .deeplang-icon{
  font-size: 42px;
}

.example .deeplang-icon {
  font-size: 32px;
}
