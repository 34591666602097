.bgBlue {
  background: #5b82e5;
  border: 1px solid #5b82e5;
}

.textBlue {
  color: #5b82e5;
}

.textWhite {
  color: #fff;
}

.textGrey {
  color: #7a7b7b;
}

.fs12 {
  font-size: 12px;
}

.fs16 {
  font-size: 16px;
}

.fs14 {
  font-size: 14px;
}

.bold {
  font-weight: 500;
}

.mt6 {
  margin-top: 6px !important;
}

.ml8 {
  margin-left: 8px;
}

.mt8 {
  margin-top: 8px;
}

.mb8 {
  margin-bottom: 8px !important;
}

.pr16 {
  padding-right: 16px;
}

.pl16 {
  padding-left: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mt20 {
  margin-top: 20px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.flex {
  display: flex;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyEnd {
  justify-content: end;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsCenter {
  align-items: center;
}

.primaryButton {
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 6px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}

.dashButton {
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 6px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  color: #1a1a1a;
  background-color: #fff;
}

.cursor {
  cursor: pointer;
}

.btnDisabled {
  border-color: #d9d9d9;
  background: #f5f5f5;
  color: #00000040;
  width: 60px;
}

.fullWidth {
  width: 100%;
}

.title {
  text-align: center;
  padding: 16px;
}

.formItemContent:global(.ant-form-item) {
  margin-bottom: 20px;
}

.formItemContent :global(.ant-space) {
  display: flex;
}

.formItemContent :global(.ant-space-item):first-child {
  flex: 1;
}

.formItemContent :global(.ant-form-item-explain-error) {
  font-size: 12px;
  font-weight: 400;
}

.formItemContent :global(.ant-input-affix-wrapper) {
  border-radius: 6px;
}

.formItemContent :global(input) {
  border-radius: 6px;
}

.formItemContent :global(button), .cancellateConfirm :global(button) {
  border-radius: 6px;
}

/* :global(.ant-modal-content) {
  border-radius: 8px !important;
} */

.formItemContent :global(.ant-input-suffix) {
  cursor: pointer;
}

.formItemContent :global(.ant-message-notice-content) {
  border-radius: 8px !important;
}

.formItemContent :global(.ant-input-affix-wrapper):focus,
.formItemContent :global(.ant-input-affix-wrapper-focused) {
  box-shadow: 0 0 0 2px #d6d6d6 !important;
}

.formItemCheck :global(.ant-checkbox-inner) {
  border-radius: 4px;
}

.formItemCheck :global(.ant-checkbox) {
  top: 0.2em;
  align-self: baseline;
}

.formItemContent :global(.ant-input-affix-wrapper-status-error):hover {
  border-color: #ff4d4f !important;
}

.formItemContent :global(.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-focused) {
  border-color: #ff4d4f !important;
}

.formItemContent :global(.ant-input-affix-wrapper-status-error):focus {
  border-color: #ff4d4f !important;
}
/* 
.formItemContent input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 0px transparent inset !important;
}

.formItemContent input:-internal-autofill-previewed,
.formItemContent input:-internal-autofill-selected {
  -webkit-text-fill-color: #2d2d2d !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.formItemContent input:-internal-autofill-selected {
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: transparent !important;
} */
