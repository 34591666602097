.backLogin {
  position: relative;
  top: -10px;
  left: -26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.title {
  margin-top: 0;
}
