.formContent {
  padding: 0px 16px;
  box-sizing: border-box;
}

.tabs {
  display: flex;
  justify-content: space-between;
  padding: 4px 26px;
}

.tabItems {
  font-size: 16px;
  padding: 15px 0px;
  cursor: pointer;
  color: #7a7b7b;
}
.tabItems:hover {
  color: #2d2d2d;
}

.tabItemsActive {
  color: #2d2d2d;
}

.codeBtton {
  margin-left: 8px;
}

.fullWidth {
  width: 100%;
}

.divider {
  margin: 0px !important;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 8px 60px;
  align-items: center;
}

.formItem {
  display: flex;
  justify-content: flex-end;
}

.formForget {
  color: #b7b8b9;
  cursor: pointer;
}
.formForget:hover {
  color: #2d2d2d;
}

.footerText a {
  text-align: right;
  color: #b7b8b9;
  margin-right: -24px;
  display: block;
}
.footerText a:hover {
  color: #2d2d2d;
}

.shaking {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(3px, 0, 0);
    transform: translate3d(3px, 0, 0);
  }
}

.weixinModal {
  width: 294px;
}

.weixinHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  padding: 36px 40px 10px 40px;
}

.weixinImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #06CF66;
  border-radius: 4px;
}

.weixinIcon svg {
  width: 18px;
  height: 18px;
}

.weixinTitle {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.weixinContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
}

.weixinLoginImage {
  width: 186px;
  height: 186px;
}

.weixinLoginImage iframe {
  width: 100%;
  height: 100%;
}

.weixinText {
  color: #7a7b7b;
  font-size: 14px;
  line-height: normal;
}

.weixinFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  font-size: 12px;
  line-height: 18px;
}
.weixinFooter a {
  color: #b7b8b9;
}

.weixinFooter a:hover {
  color: #2D2D2D;
}

.weixinRefresh {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: -8px;
  color: #b7b8b9;
  cursor: pointer;
}

.weixinRefresh:hover {
  color: #2D2D2D;
}

