.dui-operation-bar-container {

  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: rgba(45, 45, 45, 0.80);
}

.dui-operation-bar-item-container {
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dui-operation-bar-item-container:hover {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.30);
}

.dui-operation-bar-item-main {
  display: flex;
  align-items: center;
}

.dui-operation-bar-item-v2-container {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  color: var(--text-primary-inverse, #FFF);
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.dui-operation-bar-item-v2-container:hover {
  background-color: rgba(255, 255, 255, 0.30);
  border-radius: 4px;
}

.dui-operation-bar-item-v2-header {
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}