.dui-modal-header {
  display: flex;
  padding: 0px 48px;
  align-items: center;
}

.dui-modal-container div.ant-modal div.ant-modal-content {
  padding: 0px;
}

.dui-modal-container button.ant-modal-close {
  display: none;
}

.dui-modal-container div.ant-modal-header {
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
}

.dui-modal-title {
  cursor: pointer;
}

.dui-modal-footer {
  display: flex;
  padding-bottom: 32px;
}

.dui-modal-body {
  display: flex;
  width: 100%;
}

.dui-modal-input-suffix-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  border-radius: 8px;
  border: 1px solid var(--Brand-B6, #464646);
  background: var(--Brand-B6, #464646);
}

.dui-modal-header span.ant-input-affix-wrapper {
  height: 48px;
  padding: 0;
  padding-right: 4px;
  align-items: center;
}

.dui-modal-header span.ant-input-affix-wrapper:hover {
  border: 1px solid var(--Brand-B6, #464646);
}

.dui-modal-header span.ant-input-affix-wrapper:focus-within {
  box-shadow: 0px 0px 0px 2px #D6D6D6;
  border: 1px solid var(--Brand-B6, #464646);
}


.dui-modal-header span.ant-input-affix-wrapper span.ant-input-suffix {
  margin-inline-start: 0;
  cursor: pointer;
}

.dui-modal-header input.ant-input {
  margin: 4px 12px;
  height: 24px;
  font-size: 16px;
}

.dui-modal-body div.ant-tabs-nav {
  padding: 20px 48px 0px 48px;
}

.dui-modal-body .ant-tabs {
  width: 100%;
}

.dui-modal-body .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2D2D2D;
}

.dui-modal-body .ant-tabs .ant-tabs-tab {
  color: #7A7B7B;
}

.dui-modal-body .ant-tabs .ant-tabs-tab-btn:active {
  color: #2D2D2D;
}

.dui-modal-body .ant-tabs .ant-tabs-ink-bar {
  background-color: #2D2D2D;
}