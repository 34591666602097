.goback {
  position: absolute;
  top: 20px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
  cursor: pointer;
}
.goback svg {
  width: 12px;
  height: 12px;
}
.goback span {
  color: #2d2d2d;
  font-size: 12px;
  line-height: 18px;
}