.cancellateConfirm {
  padding: 8px;
  width: 336px;
}

.destroyContent {
  display: flex;
  align-items: baseline;
  font-weight: 400;
}

.confirmTips {
  padding-left: 16px;
  line-height: 24px;
}

.confirmOptions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.confirmOptions :global(.ant-btn-default)[disabled] {
  border-color: #d9d9d9;
  background: #f5f5f5;
  border-radius: 6px;
  color: #b7b8b9;
}
.confirmOptions :global(.ant-btn-default) {
  border: 1px solid #ff4d4f;
  color: #ff4d4f;
  border-radius: 6px;
}

.promptImg {
  margin-top: 8px;
}

.confirmText {
  padding-bottom: 8px;
}

.confirmOptions :global(.ant-btn-default):not(:disabled):not(.ant-btn-disabled):hover {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
