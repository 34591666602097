.dui-modal-container {
  padding: 0px;
}

.dui-modal-container .ant-modal {
  width: auto !important;
}

.dui-modal-container * {
  box-sizing: border-box;
}