.operate-icons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  color: #9e9e9e;
  height: 32px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 0 8px;
  gap: 4px;
  border-radius: 8px;
}

.operate-icons-noborder {
  border: none;
}

.operate-icons .icon-content {
  cursor: pointer;
}

.operate-icons .icon-content:hover {
  color: #2D2D2D;
}

.operate-icons__time {
  padding: 0 4px;
  font-size: 12px;
}

.operate-icons__line {
  width: 1px;
  height: 20px;
  background-color: #d9d9d9;
  margin: 0 4px;
}

.icon-content, .icon-content span {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}


