.dui-image-viewer-container {
  position: fixed;

  top: 0;
  left: 0;
  z-index: 1020;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.70);
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dui-image-viewer-container * {
  box-sizing: border-box;
}

.dui-image-viewer-header {

  display: flex;
  height: 38px;
  width: 100%;

  position: relative;
}

.dui-image-viewer-close {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  border-radius: 100px;
  background: rgba(45, 45, 45, 0.80);
  width: 52px;
  height: 52px;
  cursor: pointer;
}

.dui-image-viewer-footer {

  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 24px;
}