
.img-viewer-close {
  cursor: pointer;
  position: fixed;
  width: 52px;
  height: 52px;
  top: 24px;
  right: 24px;
  border-radius: 50%;
  padding: 0;
  background: rgba(45, 45, 45, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.custom-tooltip .ant-tooltip-inner {
  color: #333;
}

.img-viewer-toolbar-divider {
  height: 28px;
  width: 1px;
  background: rgba(241, 241, 241, 0.5);
}

.dp-icon-tooltip:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.dp-icon-tooltip-disabled {
  opacity: 0.6;
}
.ant-message {
  z-index: 10000;
}
.img-viewer .deeplang-icon,
.img-viewer .deeplang-icon svg,
.img-viewer .deeplang-icon path {
  cursor: pointer !important;
}
