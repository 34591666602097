.outline-item-level-1 {
  margin-top: 14px;
}

.outline-item-level-2 {
  margin-top: 10px;
}
.outline-item-level-3 {
  margin-top: 4px;
}

.outline-item-level-1 + .outline-item-level-2 {
  margin-top: 16px;
}

.outline-item-level-2 + .outline-item-level-3 {
  margin-top: 12px;
}
