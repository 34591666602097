.action-icons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  color: #9e9e9e;
  height: auto;
  display: inline-flex;
  align-items: center;
  /* border: 1px solid #d9d9d9; */
  background-color: transparent;
  /* padding: 0 8px; */
  gap: 4px;
  border-radius: 8px;
}

.action-icons-noborder {
  border: none;
}

.action-icons__time {
  padding: 0 4px;
  font-size: 12px;
}

.action-icons__line {
  width: 1px;
  height: 20px;
  background-color: #d9d9d9;
  margin: 0 4px;
}

.action-icons .icons-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.action-icons .icons-content .icon-item-wrap {
  display: flex;
  align-items: center;
  height: 22.5px;
}

.action-icons .icons-content .icon-item-wrap div.ant-tooltip-arrow {
  display: none;
}

.action-icons .icon-item {
  display: flex;
  width: 22.5px;
  height: 22.5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #9e9e9e;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}

.action-icons .icon-item:hover {
  color: #2D2D2D;
}

.action-icons .icon-item.selected {
  color: #2D2D2D;
}

.action-icons .icon-item.open {
  border-radius: 4px;
  background-color: #f5f5f5;
}

.action-icons .icons-sub-item .icon-item.open {
  background-color: transparent;
}
.action-icons .icons-sub-item .icon-item.open:hover {
  background-color: #f5f5f5;
}

.icons-children-card div.ant-popover-arrow {
  display: none;
}

.icons-children-card div.ant-popover-inner {
  padding: 0;
}

.icons-sub-menu {
  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
  min-width: 105px;
  padding: 4px
}

.icons-sub-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 4px;
  gap: 8;
  border-radius: 4px;
  cursor: pointer;
}

.icon-icon-popover {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.icons-sub-item:hover {
  background: #f5f5f5;
}

.icons-sub-item:hover .icon-item {
  color: #2D2D2D;
}

.icons-sub-item-text {
  margin-left: 8px;
}

.sub-item-more {
  color: #2D2D2D;
  font-size: 14px;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 8px;
}

.icons-sub-item.selected {
  background-color: #f5f5f5;
}
.icons-sub-item.selected .icons-sub-item-text {
  color: #2D2D2D;
  font-weight: 500;
}

.icons-sub-item .icon-wrap {
  display: flex;
  width: 100%;
  position: relative;;
}

.icons-sub-item .icon-wrap .icon-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.icons-sub-item.selected .icon-wrap::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 0px;
  width: 12px;
  height: 7px;
  border: 2px solid #2D2D2D;
  border-top: none;
  border-right: none;
  transform: translate(-50%, -50%) rotate(-45deg);
}



