.dui-table-viewer-item-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  min-width: 600px;
  /* min-height: 453px; */

  max-width: calc(100% - 200px);
  max-height: 100%;
  padding: 16px;
  align-items: flex-start;

  background: rgba(0, 0, 0, 0.30);
  border-radius: 12px;
  position: relative;
}


.dui-table-viewer-title {

  /* display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden; */
  min-width: 600px;
}

.dui-table-viewer-item-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  gap: 10px;

  color: var(--character-primary-inverse, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

}

.dui-table-viewer-item-col {
  min-width: 130px;
  max-width: 200px;
}

.dui-table-viewer-table div.ant-table div.ant-table-header {
  border-radius: 0;
}

.dui-table-viewer-table tr td:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.dui-table-viewer-item-col-border-radius {
  border-start-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
}